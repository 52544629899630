import React, { useEffect, useRef, useState } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import uuidv4 from 'uuid/v4';
import ReconnectingWebSocket from 'reconnecting-websocket';
import './App.css';
import {
  STATE_CREATED,
  STATE_CHOOSING,
  STATE_WAITING,
  STATE_SPECTATING,
} from './constants.mjs';

import {ThemeContext, defaultTheme} from './contexts/theme-context.js';
import IndexView from './components/views/IndexView';
import JoinView from './components/views/JoinView';
import GameView from './components/views/GameView';
import CreateView from './components/views/CreateView';
import CreateBotView from './components/views/CreateBotView';
import JoinQueueView from './components/views/JoinQueueView';

let wsUrl;
let isPageLoad = true;
if (process.env.NODE_ENV === 'production') {
  const wsScheme = window.location.protocol === 'https:' ? 'wss' : 'ws';
  wsUrl = wsScheme + '://' + window.location.host;
} else {
  wsUrl = 'ws://' + window.location.hostname + ':8080';
}

function doNothing() {}

function App() {
  const persistedSettingsInitial = {
    nickname: '',
    isGuideSeen: false,
  };

  if (window.sessionStorage) {
    if (sessionStorage.getItem('nickname')) {
      persistedSettingsInitial.nickname = sessionStorage.getItem('nickname');
    }
    if (localStorage.getItem('isGuideSeen')) {
      persistedSettingsInitial.isGuideSeen = (localStorage.getItem('isGuideSeen') === 'true');
    }
  }

  const [ws, setWs] = useState(null);
  const [theme, setTheme] = useState(defaultTheme);
  const [gameState, setGameState] = useState('connecting');
  const [clientId, setClientId] = useState(null);
  const [roomId, setRoomId] = useState(null);
  const [roomIsPublic, setRoomIsPublic] = useState(null);
  const [board, setBoard] = useState(null);
  const [gameErrors, setGameErrors] = useState(null);
  const [persistedSettings, setPersistedSettings] = useState(persistedSettingsInitial);
  const [playerCount, setPlayercount] = useState(0);
  const [opponentStatus, setOpponentStatus] = useState({isGone: false, pingFailCount: 0});
  const location = useLocation();
  const reconnectionRef = useRef(doNothing);

  const handleNicknameChange = (nickname) => {
    setPersistedSettings({...persistedSettings, nickname: nickname});
  };

  const setGuideSeen = (value = true) => {
    setPersistedSettings({...persistedSettings, isGuideSeen: value});
  };

  const getCleanedOrigin = function(url) {
    const [protocol, rest] = url.split('://');
    if ( ! protocol || ! rest) {
      return;
    }
    const [origin] = rest.split('/');
    return `${protocol}://${origin}`;
  };

  const errorMessages = ({
    'room_not_found': 'Huonetta ei löydy',
  });

  useEffect(() => {
    const clientId = getClientId();
    const ws = new ReconnectingWebSocket(wsUrl);
    setClientId(clientId);
    setWs(ws);
  }, []);

  useEffect(() => {
    if ( ! ws) {
      return;
    }
    ws.onopen = () => {
      setGameState('connected');
      if (isPageLoad) {
        isPageLoad = false;
        ws.send(JSON.stringify({
          clientId,
          action: 'load',
          ref: getCleanedOrigin(document.referrer),
        }));
      }
      ws.send(JSON.stringify({
        clientId,
        action: 'connect',
        roomId,
      }));
    };
    ws.onmessage = message => {
      const event = JSON.parse(message.data);
      switch (event.action) {
        case 'create':
          setRoomId(event.roomId);
          setBoard(event.board);
          setGameState(event.gameState);
          setRoomIsPublic(event.roomIsPublic);
          setPlayercount(event.playerCount);
          setOpponentStatus({isGone: false, pingFailCount: 0});
          break;
        case 'join':
          setRoomId(event.roomId);
          setBoard(event.board);
          setGameState(event.gameState);
          setPlayercount(event.playerCount);
          setOpponentStatus({isGone: false, pingFailCount: 0});
          break;
        case 'connect':
        case 'choose':
        case 'reset':
          setBoard(event.board);
          setGameState(event.gameState);
          setPlayercount(event.playerCount);
          break;
        case 'ping':
          setPlayercount(event.playerCount);
          break;
        case 'opponent-gone':
          setOpponentStatus({
            isGone: true,
            pingFailCount: event.pingFailCount,
          });
          break;
        case 'opponent-ok':
          setOpponentStatus({isGone: false, pingFailCount: 0});
          break;
        case 'error':
          setGameErrors(errorMessages[event.error]);
          break;
        default:
          break;
      }
    };
  }, [ws, clientId, roomId]);

  // Reconnect websocket if STATE_WAITING for too long
  // to prevent soft-locks on dropped packets
  useEffect(() => {
    const RECONNECT_INTERVAL_MILLISECONDS = 40 * 1000;
    const shouldReconnect = ws && roomId && gameState === STATE_WAITING;
    reconnectionRef.current = shouldReconnect ? ws.reconnect.bind(ws) : doNothing;
    const interval = setInterval(() => reconnectionRef.current(), RECONNECT_INTERVAL_MILLISECONDS);
    return () => clearInterval(interval);
  }, [ws, clientId, roomId, gameState]);

  useEffect(() => {
    if(gameErrors !== null) {
      setTimeout(() => {
        setTimeout(setGameErrors(null));
      }, 3000);
    }
  }, [gameErrors]);

  useEffect(() => {
    window.sessionStorage && sessionStorage.setItem('nickname', persistedSettings.nickname);
  }, [persistedSettings.nickname]);

  useEffect(() => {
    window.localStorage && localStorage.setItem('isGuideSeen', persistedSettings.isGuideSeen);
  }, [persistedSettings.isGuideSeen]);

  useEffect(() => {
    if (ws && location.pathname === '/') {
      setRoomId(null);
      setGameState('connected');
      ws.send(JSON.stringify({
        clientId,
        action: 'navigate-index',
      }));
    }
  }, [ws, location]);

  const getClientId = () => {
    if (localStorage) {
      const localClientId = localStorage.getItem('client-id');
      if (localClientId) {
        return localClientId;
      }
      else {
        const newClientId = uuidv4();
        localStorage.setItem('client-id', newClientId);
        return newClientId;
      }
    }
    return uuidv4();
  };

  const createRoom = nickname => {
    setGameState('creating');
    ws.send(JSON.stringify({
      clientId,
      action: 'create',
      nickname: nickname,
      isPublic: false,
    }));
  };

  const createBotRoom = (nickname, difficultyLevel) => {
    setGameState('creating');
    ws.send(JSON.stringify({
      clientId,
      action: 'create-bot',
      nickname: nickname,
      isPublic: false,
      difficultyLevel: difficultyLevel,
    }));
  };

  const joinQueue = nickname => {
    setGameState('joining_queue');
    ws.send(JSON.stringify({
      clientId,
      action: 'join-queue',
      nickname: nickname,
    }));
  };

  const joinRoom = (roomId, nickname) => {
    setGameState('joining');
    ws.send(JSON.stringify({
      clientId,
      action: 'join',
      roomId,
      nickname: nickname,
    }));
  };

  const chooseColor = (color) => {
    setGameState(STATE_WAITING);
    ws.send(JSON.stringify({
      clientId,
      action: 'choose',
      color,
      roomId,
    }));
  };

  const resetRoom = () => {
    ws.send(JSON.stringify({
      clientId,
      action: 'reset',
      roomId,
    }));
  };

  let redirect = null;
  if (
    roomId &&
    (
      gameState === STATE_CREATED ||
      gameState === STATE_CHOOSING ||
      gameState === STATE_WAITING ||
      gameState === STATE_SPECTATING
    )
  ) {
    redirect = `/game/${roomId.toUpperCase()}/`;
  }

  return (
    <ThemeContext.Provider value={{theme, setTheme}}>
      {redirect && <Redirect to={redirect} />}
      <Switch>
        <Route
          path="/"
          exact
          render={() => {
            return <IndexView
              playerCount={playerCount}
            />
          }}
        />
        <Route
          path="/join/:roomId/"
          render={routeProps => {
            const roomIdParam = routeProps.match.params.roomId.toLowerCase();
            setRoomId(roomIdParam);
            return <Redirect to='/join/' />
          }}
        />
        <Route
          path="/join/"
          render={() =>
            <JoinView
              joinRoom={joinRoom}
              roomId={roomId}
              nicknameHandler={handleNicknameChange}
              persistedSettings={persistedSettings}
              errors={gameErrors}
            />
          }
        />
        <Route
          path="/create/"
          render={() =>
            <CreateView
              createRoom={createRoom}
              roomId={roomId}
              nicknameHandler={handleNicknameChange}
              persistedSettings={persistedSettings}
              errors={gameErrors}
            />
          }
        />
        <Route
          path="/join-queue/"
          render={() =>
            <JoinQueueView
              joinQueue={joinQueue}
              roomId={roomId}
              nicknameHandler={handleNicknameChange}
              persistedSettings={persistedSettings}
              errors={gameErrors}
            />
          }
        />
        <Route
          path="/create-bot/"
          render={() =>
            <CreateBotView
              createBotRoom={createBotRoom}
              roomId={roomId}
              nicknameHandler={handleNicknameChange}
              persistedSettings={persistedSettings}
              errors={gameErrors}
            />
          }
        />
        <Route
          path="/game/:roomId/" render={routeProps => {
            const roomIdParam = routeProps.match.params.roomId.toLowerCase();

            if (ws && (gameState === 'connecting' || gameState === 'connected') && roomIdParam !== roomId) {
              joinRoom(roomIdParam, persistedSettings.nickname);
            }
            return <GameView
              board={board}
              roomId={roomId}
              chooseColor={chooseColor}
              resetRoom={resetRoom}
              setGuideSeen={setGuideSeen}
              gameState={gameState}
              roomIsPublic={roomIsPublic}
              opponentStatus={opponentStatus}
              persistedSettings={persistedSettings}
            />
          }}
        />
      </Switch>
    </ThemeContext.Provider>
  );
}

export default App;
