import React from 'react';

export const COLOR_INDEX = {
  'RED': 0,
  'GREEN': 1,
  'BLUE': 2,
  'YELLOW': 3,
  'PURPLE': 4,
};

export const PERSPECTIVE = {
  A: 'a',
  B: 'b',
};

const DEFAULT_THEME_ID = 'eggplant-shaded';

export const themes = {};


class Theme {
  constructor({
    name,
    backgroundImage,
    boardRendererClass,
    colors,
    ...extra
  }) {
    this.name = name;
    this.backgroundImage = backgroundImage;
    this.colors = {};
    for (let [colorIndex, color] of colors) {
      this.colors[colorIndex] = color;
    }
    this.extra = extra;
    this.boardRenderer = new boardRendererClass();
    this.boardRenderer.setTheme(this);
  }

  getColorByIndex(colorIndex) {
    return this.colors[colorIndex] || 'black';
  }
}


class RectangleBoardRenderer {
  constructor() {
    this.initialBoardWidth = 40;
    this.initialBoardHeight = 27;
    this.boardWidth = this.initialBoardWidth;
    this.boardHeight = this.initialBoardHeight;
    this.renderedTileWidth = 0;
    this.renderedTileHeight = 0;
    this.canvas = undefined;
    this.context = undefined;
    this.theme = undefined;
    this.perspectiveId = undefined;
  }

  setTheme(theme) {
    this.theme = theme;
  }

  initRotation() {
    if (this.isPortrait) {
      this.boardWidth = this.initialBoardHeight;
      this.boardHeight = this.initialBoardWidth;
    }
    else {
      this.boardWidth = this.initialBoardWidth;
      this.boardHeight = this.initialBoardHeight;
    }
    this.rotateDegrees = (
      this.perspectiveId === PERSPECTIVE.B
      ?
      (this.isPortrait ? 270 : 180)
      :
      (this.isPortrait ? 90 : 0)
    );
  }

  drawBoard(canvas, board, isPortrait) {
    this.perspectiveId = board.thisPlayer && board.thisPlayer.perspectiveId;
    this.isPortrait = isPortrait;
    this.initRotation();
    this.canvas = canvas;
    this.renderedTileWidth = canvas.width / this.boardWidth;
    this.renderedTileHeight = canvas.height / this.boardHeight;
    this.context = canvas.getContext('2d');
    this.context.clearRect(0, 0, canvas.width, canvas.height);
  }

  rotateDrawData(degrees, {x, y, drawBits}) {
    if (degrees) {
      const radians = degrees * (Math.PI / 180);
      let correctedX = x * Math.cos(radians) - y * Math.sin(radians);
      let correctedY = x * Math.sin(radians) + y * Math.cos(radians);
      if (degrees === 90) {
        correctedX += this.boardWidth - 1;
      }
      if (degrees === 180) {
        correctedX += this.boardWidth - 1;
        correctedY += this.boardHeight - 1;
      }
      if (degrees === 270) {
        correctedY += this.boardHeight - 1;
      }
      const bitsSize = 8;
      const bitsToShift = bitsSize - bitsSize * (degrees / 360);
      const bitmask = 0b11111111;
      const correctedDrawBits = ((drawBits >>> bitsToShift) | (drawBits << (bitsSize - bitsToShift))) & bitmask;
      return {
        x: correctedX,
        y: correctedY,
        drawBits: correctedDrawBits,
      };
    }
    else {
      return {
        x,
        y,
        drawBits,
      };
    }
  }
}


class FlatColorRenderer extends RectangleBoardRenderer {
  drawBoard(canvas, board, isPortrait) {
    super.drawBoard(canvas, board, isPortrait);
    for (const cell of board.cells) {
      const colorStyle = this.theme.getColorByIndex(cell.colorIndex);
      const drawData = this.rotateDrawData(this.rotateDegrees, cell.drawData);
      this.context.fillStyle = colorStyle;
      this.context.fillRect(
        drawData.x * this.renderedTileWidth,
        drawData.y * this.renderedTileHeight,
        this.renderedTileWidth,
        this.renderedTileHeight,
      );
    }
  }
}


class TilesetRenderer extends RectangleBoardRenderer {
  constructor() {
    super();
    this.tilesetTileWidth = 20;
    this.tilesetTileHeight = 20;
    this.metatiles = {
      'base': [0, 0],
      'edge_1': [1, 0],
      'edge_2': [2, 0],
      'edge_3': [3, 0],
      'edge_4': [4, 0],

      'corner_1_inset': [1, 1],
      'corner_1_outset': [2, 1],
      'corner_1_horizontal': [3, 1],
      'corner_1_vertical': [4, 1],

      'corner_2_inset': [1, 2],
      'corner_2_outset': [2, 2],
      'corner_2_horizontal': [4, 2],
      'corner_2_vertical': [3, 2],

      'corner_3_inset': [1, 3],
      'corner_3_outset': [2, 3],
      'corner_3_horizontal': [3, 3],
      'corner_3_vertical': [4, 3],

      'corner_4_inset': [2, 4],
      'corner_4_outset': [1, 4],
      'corner_4_horizontal': [3, 4],
      'corner_4_vertical': [4, 4],
    };
    // "is blocked" indicates whether adjacent tiles are connected or not.
    this.is_blocked = {
      'up_left':    0b00000001,
      'up':         0b00000010,
      'up_right':   0b00000100,
      'right':      0b00001000,
      'down_right': 0b00010000,
      'down':       0b00100000,
      'down_left':  0b01000000,
      'left':       0b10000000,
    };
  }

  setTheme(theme) {
    super.setTheme(theme);
    this.tilesetImage = this.getTilesetImage();
  }

  getTilesetImage() {
    if (this.theme.extra.tilesetImageUrl) {
      const image = new Image();
      image.src = this.theme.extra.tilesetImageUrl;
      return image;
    }
    else {
      return this.renderTileset();
    }
  }

  renderTileset() {
    const tilesetCanvas = document.createElement('canvas');
    const colorCount = this.theme.extra.tilesetColors.length;
    const tileWidth = this.tilesetTileWidth;
    const tileHeight = this.tilesetTileHeight;
    const borderWidth = 2;
    const tileSetWidth = tileWidth * 5;
    const tileSetHeight = tileHeight * 5;
    tilesetCanvas.width = tileSetWidth * colorCount;
    tilesetCanvas.height = tileSetHeight;
    const context = tilesetCanvas.getContext('2d');
    for (const [index, [colorIndex, colors]] of this.theme.extra.tilesetColors.entries()) {
      const offset = index * tileSetWidth;
      context.fillStyle = colors.background;
      context.fillRect(offset + 0, 0, tileWidth, tileWidth);
      context.fillStyle = colors.top;
      context.fillRect(offset + tileWidth + borderWidth, 0, tileWidth - borderWidth * 2, borderWidth);
      context.fillRect(offset + 3 * tileWidth, tileHeight, borderWidth, borderWidth);
      context.fillRect(offset + 2 * tileWidth - borderWidth, 2 * tileHeight, borderWidth, borderWidth);
      context.fillRect(offset + 3 * tileWidth - borderWidth, 2 * tileHeight, borderWidth, borderWidth);
      context.fillRect(offset + 5 * tileWidth - borderWidth, 2 * tileHeight, borderWidth, borderWidth);
      context.fillStyle = colors.left;
      context.fillRect(offset + 4 * tileWidth, borderWidth, borderWidth, tileHeight - borderWidth * 2);
      context.fillRect(offset + tileWidth, tileHeight, borderWidth, borderWidth);
      context.fillRect(offset + 2 * tileWidth, tileHeight, borderWidth, borderWidth);
      context.fillRect(offset + 4 * tileWidth, tileHeight, borderWidth, borderWidth);
      context.fillRect(offset + 4 * tileWidth, 5 * tileHeight - borderWidth, borderWidth, borderWidth);
      context.fillStyle = colors.right;
      context.fillRect(offset + 3 * tileWidth - borderWidth, borderWidth, borderWidth, tileHeight - borderWidth * 2);
      context.fillRect(offset + 4 * tileWidth - borderWidth, 2 * tileHeight, borderWidth, borderWidth);
      context.fillRect(offset + 2 * tileWidth - borderWidth, 4 * tileHeight - borderWidth, borderWidth, borderWidth);
      context.fillRect(offset + 3 * tileWidth - borderWidth, 4 * tileHeight - borderWidth, borderWidth, borderWidth);
      context.fillRect(offset + 5 * tileWidth - borderWidth, 4 * tileHeight - borderWidth, borderWidth, borderWidth);
      context.fillStyle = colors.bottom;
      context.fillRect(offset + 3 * tileWidth + borderWidth, tileHeight - borderWidth, tileWidth - borderWidth * 2, borderWidth);
      context.fillRect(offset + 4 * tileWidth - borderWidth, 4 * tileHeight - borderWidth, borderWidth, borderWidth);
      context.fillRect(offset + tileWidth, 5 * tileHeight - borderWidth, borderWidth, borderWidth);
      context.fillRect(offset + 2 * tileWidth, 5 * tileHeight - borderWidth, borderWidth, borderWidth);
      context.fillRect(offset + 3 * tileWidth, 5 * tileHeight - borderWidth, borderWidth, borderWidth);
      context.fillStyle = colors.top;
      context.fillRect(offset + tileWidth, tileHeight + borderWidth / 2, borderWidth / 2, borderWidth / 2);
      context.fillRect(offset + 2 * tileWidth + borderWidth / 2, tileHeight, borderWidth / 2, borderWidth / 2);
      context.fillStyle = colors.left;
      context.fillRect(offset + tileWidth, 5 * tileHeight - borderWidth, borderWidth / 2, borderWidth / 2);
      context.fillRect(offset + 2 * tileWidth + borderWidth / 2, 5 * tileHeight - borderWidth / 2, borderWidth / 2, borderWidth / 2);
      context.fillStyle = colors.right;
      context.fillRect(offset + 2 * tileWidth - borderWidth, 2 * tileHeight, borderWidth / 2, borderWidth / 2);
      context.fillRect(offset + 3 * tileWidth - borderWidth / 2, 2 * tileHeight + borderWidth / 2, borderWidth / 2, borderWidth / 2);
      context.fillStyle = colors.bottom;
      context.fillRect(offset + 2 * tileWidth - borderWidth / 2, 4 * tileHeight - borderWidth, borderWidth / 2, borderWidth / 2);
      context.fillRect(offset + 3 * tileWidth - borderWidth, 4 * tileHeight - borderWidth / 2, borderWidth / 2, borderWidth / 2);
    }
    return tilesetCanvas;
  }

  drawBoard(canvas, board, isPortrait) {
    super.drawBoard(canvas, board, isPortrait);
    this.context.imageSmoothingEnabled = false;
    for (const cell of board.cells) {
      const drawData = this.rotateDrawData(this.rotateDegrees, cell.drawData);
      const tileBits = drawData.drawBits;
      const is_blocked_up_left = tileBits & this.is_blocked.up_left;
      const is_blocked_up = tileBits & this.is_blocked.up;
      const is_blocked_up_right = tileBits & this.is_blocked.up_right;
      const is_blocked_right = tileBits & this.is_blocked.right;
      const is_blocked_down_right = tileBits & this.is_blocked.down_right;
      const is_blocked_down = tileBits & this.is_blocked.down;
      const is_blocked_down_left = tileBits & this.is_blocked.down_left;
      const is_blocked_left = tileBits & this.is_blocked.left;
      this.drawTile(cell, this.metatiles.base);
      // Edges
      if (is_blocked_up) {
        this.drawTile(cell, this.metatiles.edge_1);
      }
      if (tileBits & this.is_blocked.right) {
        this.drawTile(cell, this.metatiles.edge_2);
      }
      if (tileBits & this.is_blocked.down) {
        this.drawTile(cell, this.metatiles.edge_3);
      }
      if (tileBits & this.is_blocked.left) {
        this.drawTile(cell, this.metatiles.edge_4);
      }
      // Corner 1
      if (is_blocked_left && is_blocked_up) {
        this.drawTile(cell, this.metatiles.corner_1_outset);
      }
      else if (is_blocked_left) {
        this.drawTile(cell, this.metatiles.corner_1_vertical);
      }
      else if (is_blocked_up) {
        this.drawTile(cell, this.metatiles.corner_1_horizontal);
      }
      else if (is_blocked_up_left) {
        this.drawTile(cell, this.metatiles.corner_1_inset);
      }
      // Corner 2
      if (is_blocked_up && is_blocked_right) {
        this.drawTile(cell, this.metatiles.corner_2_outset);
      }
      else if (is_blocked_right) {
        this.drawTile(cell, this.metatiles.corner_2_vertical);
      }
      else if (is_blocked_up) {
        this.drawTile(cell, this.metatiles.corner_2_horizontal);
      }
      else if (is_blocked_up_right) {
        this.drawTile(cell, this.metatiles.corner_2_inset);
      }
      // Corner 3
      if (is_blocked_right && is_blocked_down) {
        this.drawTile(cell, this.metatiles.corner_3_outset);
      }
      else if (is_blocked_right) {
        this.drawTile(cell, this.metatiles.corner_3_vertical);
      }
      else if (is_blocked_down) {
        this.drawTile(cell, this.metatiles.corner_3_horizontal);
      }
      else if (is_blocked_down_right) {
        this.drawTile(cell, this.metatiles.corner_3_inset);
      }
      // Corner 4
      if (is_blocked_down && is_blocked_left) {
        this.drawTile(cell, this.metatiles.corner_4_outset);
      }
      else if (is_blocked_left) {
        this.drawTile(cell, this.metatiles.corner_4_vertical);
      }
      else if (is_blocked_down) {
        this.drawTile(cell, this.metatiles.corner_4_horizontal);
      }
      else if (is_blocked_down_left) {
        this.drawTile(cell, this.metatiles.corner_4_inset);
      }
    }
  }

  drawTile(cell, positionInTileset) {
    const drawData = this.rotateDrawData(this.rotateDegrees, cell.drawData);
    const [tilesetTileX, tilesetTileY] = positionInTileset;
    const tilesetSingleColorWidth = this.tilesetTileWidth * 5;
    this.context.drawImage(
      this.tilesetImage,
      tilesetTileX * this.tilesetTileWidth + (cell.colorIndex * tilesetSingleColorWidth),
      tilesetTileY * this.tilesetTileHeight,
      this.tilesetTileWidth,
      this.tilesetTileHeight,
      drawData.x * this.renderedTileWidth,
      drawData.y * this.renderedTileHeight,
      this.renderedTileWidth,
      this.renderedTileHeight,
    );
  }
}


themes['purple-flat'] = new Theme({
  'name': 'Untitled:Flat',
  'backgroundImage': '',
  'boardRendererClass': FlatColorRenderer,
  'colors': [
    [COLOR_INDEX.RED, '#ffffff'],
    [COLOR_INDEX.GREEN, '#ff8ae8'],
    [COLOR_INDEX.BLUE, '#ff1cd2'],
    [COLOR_INDEX.YELLOW, '#8d0ab2'],
    [COLOR_INDEX.PURPLE, '#5b0a62'],
  ],
});

themes['purple-shaded'] = new Theme({
  'name': 'Untitled:Shaded',
  'backgroundImage': '',
  'boardRendererClass': TilesetRenderer,
  'colors': [
    [COLOR_INDEX.RED, '#ffffff'],
    [COLOR_INDEX.GREEN, '#ff8ae8'],
    [COLOR_INDEX.BLUE, '#ff1cd2'],
    [COLOR_INDEX.YELLOW, '#8d0ab2'],
    [COLOR_INDEX.PURPLE, '#5b0a62'],
  ],
  'tilesetColors': [
    [COLOR_INDEX.RED, {background: '#f3f3f3', top: '#ffffff', left: '#ffffff', right: '#b5b5b5', bottom: '#b5b5b5'}],
    [COLOR_INDEX.GREEN, {background: '#ff8ae8', top: '#ffadef', left: '#ffadef', right: '#e56ece', bottom: '#e56ece'}],
    [COLOR_INDEX.BLUE, {background: '#ff1cd2', top: '#ff65e1', left: '#ff65e1', right: '#da13b3', bottom: '#da13b3'}],
    [COLOR_INDEX.YELLOW, {background: '#8d0ab2', top: '#ab49c7', left: '#ab49c7', right: '#760097', bottom: '#760097'}],
    [COLOR_INDEX.PURPLE, {background: '#5b0a62', top: '#7c2384', left: '#7c2384', right: '#410048', bottom: '#410048'}],
  ],
});

themes['greyscale-flat'] = new Theme({
  'name': 'Greyscale:Flat',
  'backgroundImage': '',
  'boardRendererClass': FlatColorRenderer,
  'colors': [
    [COLOR_INDEX.RED, '#ffffff'],
    [COLOR_INDEX.GREEN, '#bfbfbf'],
    [COLOR_INDEX.BLUE, '#808080'],
    [COLOR_INDEX.YELLOW, '#404040'],
    [COLOR_INDEX.PURPLE, '#000000'],
  ],
});

themes['classic-flat'] = new Theme({
  'name': 'Classic:Flat',
  'backgroundImage': '',
  'boardRendererClass': FlatColorRenderer,
  'colors': [
    [COLOR_INDEX.RED, '#cc3232'],
    [COLOR_INDEX.GREEN, '#39c54d'],
    [COLOR_INDEX.BLUE, '#4757b7'],
    [COLOR_INDEX.YELLOW, '#eaed11'],
    [COLOR_INDEX.PURPLE, '#cb33c9'],
  ],
});

themes['classic-shaded'] = new Theme({
  'name': 'Classic:Shaded',
  'backgroundImage': '',
  'boardRendererClass': TilesetRenderer,
  'colors': [
    [COLOR_INDEX.RED, '#cc3232'],
    [COLOR_INDEX.GREEN, '#39c54d'],
    [COLOR_INDEX.BLUE, '#4757b7'],
    [COLOR_INDEX.YELLOW, '#eaed11'],
    [COLOR_INDEX.PURPLE, '#cb33c9'],
  ],
  'tilesetColors': [
    [COLOR_INDEX.RED, {background: '#cc3232', top: '#e69a9a', left: '#e69a9a', right: '#671919', bottom: '#671919'}],
    [COLOR_INDEX.GREEN, {background: '#39c54d', top: '#9de3a7', left: '#9de3a7', right: '#1d6327', bottom: '#1d6327'}],
    [COLOR_INDEX.BLUE, {background: '#4757b7', top: '#a4acdc', left: '#a4acdc', right: '#242c5c', bottom: '#242c5c'}],
    [COLOR_INDEX.YELLOW, {background: '#eaed11', top: '#f5f789', left: '#f5f789', right: '#767709', bottom: '#767709'}],
    [COLOR_INDEX.PURPLE, {background: '#cb33c9', top: '#e69ae5', left: '#e69ae5', right: '#661a65', bottom: '#661a65'}],
  ],
});

themes['material-flat'] = new Theme({
  'name': 'Material:Flat',
  'backgroundImage': '',
  'boardRendererClass': FlatColorRenderer,
  'colors': [
    [COLOR_INDEX.RED, '#ff5252'],
    [COLOR_INDEX.GREEN, '#00c853'],
    [COLOR_INDEX.BLUE, '#3d5afe'],
    [COLOR_INDEX.YELLOW, '#ffea00'],
    [COLOR_INDEX.PURPLE, '#d500f9'],
  ],
});

themes['material-shaded'] = new Theme({
  'name': 'Material:Shaded',
  'backgroundImage': '',
  'boardRendererClass': TilesetRenderer,
  'colors': [
    [COLOR_INDEX.RED, '#ff5252'],
    [COLOR_INDEX.GREEN, '#00c853'],
    [COLOR_INDEX.BLUE, '#3d5afe'],
    [COLOR_INDEX.YELLOW, '#ffea00'],
    [COLOR_INDEX.PURPLE, '#d500f9'],
  ],
  'tilesetColors': [
    [COLOR_INDEX.RED, {background: '#FF5252', top: '#FF9999', left: '#FF9999', right: '#B33A3A', bottom: '#B33A3A'}],
    [COLOR_INDEX.GREEN, {background: '#00C853', top: '#00FF6A', left: '#00FF6A', right: '#007F35', bottom: '#007F35'}],
    [COLOR_INDEX.BLUE, {background: '#3D5AFE', top: '#98A7FE', left: '#98A7FE', right: '#2B3FB1', bottom: '#2B3FB1'}],
    [COLOR_INDEX.YELLOW, {background: '#FFEA00', top: '#FFF9BB', left: '#FFF9BB', right: '#B2A300', bottom: '#B2A300'}],
    [COLOR_INDEX.PURPLE, {background: '#D500F9', top: '#ED81FF', left: '#ED81FF', right: '#9800B2', bottom: '#9800B2'}],
  ],
});

themes['eggplant-flat'] = new Theme({
  'name': 'Eggplant:Flat',
  'backgroundImage': '',
  'boardRendererClass': FlatColorRenderer,
  'colors': [
    [COLOR_INDEX.RED, '#7B3294'],
    [COLOR_INDEX.GREEN, '#C2A5CF'],
    [COLOR_INDEX.BLUE, '#F7F7F7'],
    [COLOR_INDEX.YELLOW, '#A6DBA0'],
    [COLOR_INDEX.PURPLE, '#008837'],
  ],
});

themes['eggplant-shaded'] = new Theme({
  'name': 'Eggplant:Shaded',
  'backgroundImage': '',
  'boardRendererClass': TilesetRenderer,
  'colors': [
    [COLOR_INDEX.RED, '#7B3294'],
    [COLOR_INDEX.GREEN, '#C2A5CF'],
    [COLOR_INDEX.BLUE, '#F7F7F7'],
    [COLOR_INDEX.YELLOW, '#A6DBA0'],
    [COLOR_INDEX.PURPLE, '#008837'],
  ],
  'tilesetColors': [
    [COLOR_INDEX.RED, {background: '#7B3294', top: '#D456FF', left: '#D456FF', right: '#3C1848', bottom: '#3C1848'}],
    [COLOR_INDEX.GREEN, {background: '#C2A5CF', top: '#EFCBFF', left: '#EFCBFF', right: '#6F5F77', bottom: '#6F5F77'}],
    [COLOR_INDEX.BLUE, {background: '#F7F7F7', top: '#FFFFFF', left: '#FFFFFF', right: '#7C7C7C', bottom: '#7C7C7C'}],
    [COLOR_INDEX.YELLOW, {background: '#A6DBA0', top: '#C1FFBA', left: '#C1FFBA', right: '#5C7959', bottom: '#5C7959'}],
    [COLOR_INDEX.PURPLE, {background: '#008837', top: '#00B94B', left: '#00B94B', right: '#00441C', bottom: '#00441C'}],
  ],
});

themes['redblue-flat'] = new Theme({
  'name': 'Redblue:Flat',
  'backgroundImage': '',
  'boardRendererClass': FlatColorRenderer,
  'colors': [
    [COLOR_INDEX.RED, '#D7191C'],
    [COLOR_INDEX.GREEN, '#FDAE61'],
    [COLOR_INDEX.BLUE, '#FFFFBF'],
    [COLOR_INDEX.YELLOW, '#ABD9E9'],
    [COLOR_INDEX.PURPLE, '#2C7BB6'],
  ],
});

themes['redblue-shaded'] = new Theme({
  'name': 'Redblue:Shaded',
  'backgroundImage': '',
  'boardRendererClass': TilesetRenderer,
  'colors': [
    [COLOR_INDEX.RED, '#D7191C'],
    [COLOR_INDEX.GREEN, '#FDAE61'],
    [COLOR_INDEX.BLUE, '#FFFFBF'],
    [COLOR_INDEX.YELLOW, '#ABD9E9'],
    [COLOR_INDEX.PURPLE, '#2C7BB6'],
  ],
  'tilesetColors': [
    [COLOR_INDEX.RED, {background: '#D7191C', top: '#FF797B', left: '#FF797B', right: '#921013', bottom: '#921013'}],
    [COLOR_INDEX.GREEN, {background: '#FDAE61', top: '#FFD0A3', left: '#FFD0A3', right: '#AC7642', bottom: '#AC7642'}],
    [COLOR_INDEX.BLUE, {background: '#FFFFBF', top: '#FFFFFF', left: '#FFFFFF', right: '#8B8B68', bottom: '#8B8B68'}],
    [COLOR_INDEX.YELLOW, {background: '#ABD9E9', top: '#D9F5FF', left: '#D9F5FF', right: '#708F99', bottom: '#708F99'}],
    [COLOR_INDEX.PURPLE, {background: '#2C7BB6', top: '#3EACFF', left: '#3EACFF', right: '#1D527A', bottom: '#1D527A'}],
  ],
});


export const defaultTheme = themes[DEFAULT_THEME_ID];
export const ThemeContext = React.createContext(defaultTheme);
