import React, { useRef, useContext, useEffect } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import GameOverModal from '../GameOverModal'
import WaitingModal from '../WaitingModal'
import OpponentGoneModal from '../OpponentGoneModal'
import GuideModal from '../GuideModal'
import {
  STATE_CREATED,
  STATE_CHOOSING,
  STATE_SPECTATING,
  STATE_END,
} from '../../constants.mjs';
import {ThemeContext, COLOR_INDEX, PERSPECTIVE} from '../../contexts/theme-context.js';

export default function GameView({
  board,
  roomId,
  chooseColor,
  resetRoom,
  setGuideSeen,
  gameState,
  roomIsPublic,
  opponentStatus,
  persistedSettings,
}) {
  const canvasRef = useRef(null);
  const themeContext = useContext(ThemeContext);
  const isPortraitBreakpoint = useMediaQuery('(max-width: 1200px)');

  const canvasWidth = (isPortraitBreakpoint ? 810 : 1200) * 2;
  const canvasHeight = (isPortraitBreakpoint ? 1200 : 810) * 2;

  let [playerA, playerB] = [{}, {}];
  if (board) {
    [playerA, playerB] = board.players;
    if (board.thisPlayer && board.thisPlayer.perspectiveId === PERSPECTIVE.B) {
      [playerB, playerA] = board.players;
    }
  }

  const isPlayer = board && board.thisPlayer;
  const isSpectator = ! isPlayer;
  const isBotBattle = playerA.isBot && playerB.isBot;
  const isFirstGame = playerA.winCount === 0 && playerB.winCount === 0;

  let endMessage = '';
  if (gameState === STATE_END && board.winningPlayer) {
    if (isSpectator) {
      endMessage = `${board.winningPlayer.nickname} voitti pelin!`;
    }
    else if (board.winningPlayer.id === board.thisPlayer.id) {
      endMessage = 'Voitit pelin!';
    }
    else {
      endMessage = 'Hävisit pelin!';
    }
  }
  if (gameState === STATE_END && board.isTie) {
    endMessage = '🎉 Vau! Tasapeli! 🎉';
  }

  useEffect(() => {
    if (canvasRef.current && board) {
      const canvas = canvasRef.current;
      themeContext.theme.boardRenderer.drawBoard(canvas, board, isPortraitBreakpoint);
    }
  }, [canvasRef, board, themeContext.theme, isPortraitBreakpoint]);

  function getScorePercentage(targetScore, playerScore) {
    return Math.min(100, playerScore / targetScore * 100);
  }

  function getShowOpponentGoneModal() {
    const pingFailThreshold = 2;
    return opponentStatus.isGone && opponentStatus.pingFailCount >= pingFailThreshold;
  }

  function getOpponentGoneNameClass(playerId) {
    console.log(opponentStatus);
    if (board && board.thisPlayer && opponentStatus.isGone && playerId != board.thisPlayer.id) {
      return ' player-name-gone';
    }
    return '';
  }

  return (
    <React.Fragment>
      { gameState === STATE_END &&
        <GameOverModal endMessage={endMessage} resetRoom={isSpectator && ! isBotBattle ? undefined : resetRoom} />
      }
      { gameState === STATE_CREATED &&
        <WaitingModal roomId={roomId} roomIsPublic={roomIsPublic} />
      }
      { getShowOpponentGoneModal() && gameState !== STATE_END && gameState !== STATE_CREATED &&
        <OpponentGoneModal />
      }
      { isPlayer && !persistedSettings.isGuideSeen && gameState !== STATE_END && gameState !== STATE_CREATED &&
        <GuideModal dismissGuideModal={() => setGuideSeen(true)} />
      }
      <div className="gameview-container">
        <div className="gameview-layout">
          <div className='player-container player-container-secondary'>
            <div className={'player-name' + getOpponentGoneNameClass(playerB.id)}>
              {board && playerB && gameState === STATE_SPECTATING && board.playerInTurn.id === playerB.id &&
                <div className='player-color-options'>
                  {Object.values(COLOR_INDEX).map(color => {
                    const isDisabled = board.choosableColors !== null && ! board.choosableColors.includes(color);
                    const style = {
                      'backgroundColor': themeContext.theme.getColorByIndex(color),
                    };
                    return (
                      <div
                        className='player-color-option'
                        style={style}
                        disabled={isDisabled}
                        key={color}
                      ></div>
                    );
                  })}
                </div>
              }
              {board && !isFirstGame && !isPortraitBreakpoint &&
                <span className="player-win-count">
                  [{playerB.winCount}]
                </span>
              }
              {playerB.nickname || 'Pelaaja 2'}
              {board && !isFirstGame && isPortraitBreakpoint &&
                <span className="player-win-count">
                  [{playerB.winCount}]
                </span>
              }
            </div>
          </div>
          <div className='game-board'>
          <canvas
            ref={canvasRef}
            width={canvasWidth}
            height={canvasHeight}
          />
          </div>
          <div className='player-container player-container-primary'>
            <div className={'player-name' + getOpponentGoneNameClass(playerA.id)}>
              {board && !isFirstGame &&
                <span className="player-win-count">
                  [{playerA.winCount}]
                </span>
              }
              {playerA.nickname || 'Pelaaja 1'}
              {board && playerA && gameState === STATE_SPECTATING && board.playerInTurn.id === playerA.id &&
                <div className='player-color-options'>
                  {Object.values(COLOR_INDEX).map(color => {
                    const isDisabled = board.choosableColors !== null && ! board.choosableColors.includes(color);
                    const style = {
                      'backgroundColor': themeContext.theme.getColorByIndex(color),
                    };
                    return (
                      <div
                        className='player-color-option'
                        style={style}
                        disabled={isDisabled}
                        key={color}
                      ></div>
                    );
                  })}
                </div>
              }
            </div>
            <div className='color-options'>
              {board && gameState === STATE_CHOOSING && Object.values(COLOR_INDEX).map(color => {
                const isDisabled = board.choosableColors !== null && ! board.choosableColors.includes(color);
                const style = {
                  'backgroundColor': themeContext.theme.getColorByIndex(color),
                };
                return (
                  <button
                    className='color-option'
                    style={style}
                    onClick={() => chooseColor(color)}
                    disabled={isDisabled}
                    key={color}
                  ></button>
                );
              })}
            </div>
            {board &&
              <div className='scoreboard'>
                {board.players && (
                  (
                    board.thisPlayer && board.thisPlayer.perspectiveId === PERSPECTIVE.B
                    ?
                    (isPortraitBreakpoint ? board.players.slice() : board.players.slice().reverse())
                    :
                    (isPortraitBreakpoint ? board.players.slice().reverse() : board.players.slice())
                  ).map(player =>
                    <div
                      className='score-progress-bar'
                      style={{
                        'backgroundSize': `${getScorePercentage(board.targetScore, player.score)}% 100%`,
                        'color': themeContext.theme.getColorByIndex(player.currentColorIndex),
                      }}
                      key={player.id}
                    ></div>
                  )
                )}
              </div>
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
