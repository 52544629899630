import React from 'react'
import { useHistory } from 'react-router-dom';
import { Button, Box } from '@material-ui/core'

export default function GameOverModal({ endMessage, resetRoom }) {

  const history = useHistory();

  return (
    <div className="game-over-modal">
      <div>
        <p>{endMessage}</p>
        { !!resetRoom &&
          <Box>
            <Button
              onClick={resetRoom}
              variant="contained"
              color="primary"
              aria-label="full-width contained primary button group"
            >
              Pelaa uudelleen
            </Button>
          </Box>
        }
        <Box>
          <Button
            onClick={() => history.push('/')}
            variant="contained"
            color="primary"
            aria-label="full-width contained primary button group"
            style={{ marginTop: 20 }}
          >
            Etusivulle
          </Button>
        </Box>
      </div>
    </div>
  )
}
