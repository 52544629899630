import React from 'react'
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const CollisionLinkQueue = React.forwardRef((props, ref) => (
  <Link innerRef={ref} to="/join-queue/" {...props} />
));
const CollisionLinkCreate = React.forwardRef((props, ref) => (
  <Link innerRef={ref} to="/create/" {...props} />
));
const CollisionLinkJoin = React.forwardRef((props, ref) => (
  <Link innerRef={ref} to="/join/" {...props} />
));
const CollisionLinkCreateBot = React.forwardRef((props, ref) => (
  <Link innerRef={ref} to="/create-bot/" {...props} />
));

const useStyles = makeStyles(theme => ({
  buttonWrapper: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    minWidth: 250,
    margin: 4,
  },
  secondaryButton: {
    minWidth: 250,
    margin: 4,
    backgroundColor: '#000',
    border: '1px solid #6100ed'
  }
}));

export default function IndexView({ playerCount }) {
  const classes = useStyles();

  const openDiscordLink = () => {
    const baseUrl = 'https://discord.gg/';
    const key = 'ACUkvw';
    const url = [baseUrl, key[0], key[4], key[5], key[2], key[5], key[1], key[3]].join('');
    window.open(url, 'discord', 'noreferrer');
  };

  return (
    <div className="centered-container index-view">
      <p className="big-header">Värivaltaus</p>
      <p className="sub-header">Aloita uusi peli tai liity koodilla olemassa olevaan peliin</p>
      <div className={classes.buttonWrapper}>
        <Button component={CollisionLinkQueue} className={classes.button} color="primary" variant="contained">Pelaa</Button>
        <Button component={CollisionLinkCreate} className={classes.secondaryButton} color="primary" variant="contained">Kutsu kaveri</Button>
        <Button component={CollisionLinkJoin} className={classes.secondaryButton} color="primary" variant="contained">Liity koodilla</Button>
        <Button component={CollisionLinkCreateBot} className={classes.secondaryButton} color="primary" variant="contained">Pelaa tietokonetta vastaan</Button>
        <Button onClick={openDiscordLink} className={classes.secondaryButton} color="primary" variant="contained">Juttele Discordissa</Button>
      </div>
      <p className="player-count">Pelaajia: {playerCount}</p>
    </div>
  )
}
