import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { Button, ButtonGroup } from '@material-ui/core'

export default function WaitingModal({ roomId, roomIsPublic }) {
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const history = useHistory();

  return (
    <div className="waiting-modal">
      {roomIsPublic ?
        <div>
          <p className="sub-header">Etsitään vastustajaa</p>
          <ButtonGroup
            variant="contained"
            color="primary"
            aria-label="full-width contained primary button group"
          >
            <Button onClick={() => history.push('/')}>Poistu pelistä</Button>
          </ButtonGroup>
        </div>
        :
        <div>
          <p className="sub-header">Odotetaan vastustajaa.<br />Kutsu vastustajasi jakamalla linkki tai allaoleva kutsukoodi.</p>
          {
            navigator.share
              ?
              (
                <ButtonGroup
                  style={{
                    transform: 'scale(1.8)',
                    margin: '30px',
                  }}
                  variant="contained"
                  color="primary"
                  aria-label="full-width contained primary button group"
                >
                  <Button
                    onClick={() => navigator.share({
                      title: '',
                      text: '',
                      url: `https://${window.location.host}/join/${roomId.toUpperCase()}/`,
                    })}
                  >
                    Jaa linkki
              </Button>
                </ButtonGroup>
              )
              :
              (
                navigator.clipboard
                  ?
                  (
                    <ButtonGroup
                      style={{
                        transform: 'scale(1.8)',
                        margin: '30px',
                      }}
                      variant="contained"
                      color="primary"
                      aria-label="full-width contained primary button group"
                    >
                      <Button
                        onClick={event => {
                          navigator.clipboard.writeText(`https://${window.location.host}/join/${roomId.toUpperCase()}/`);
                          setIsLinkCopied(true);
                        }}
                      >
                        {isLinkCopied ? 'Linkki kopioitu' : 'Kopioi linkki'}
                      </Button>
                    </ButtonGroup>
                  )
                  :
                  (
                    <p>
                      <a
                        href={`https://${window.location.host}/join/${roomId.toUpperCase()}/`}
                        onClick={event => event.preventDefault()}
                        className="share-link"
                      >
                        {window.location.host}/join/{roomId.toUpperCase()}
                      </a>
                    </p>
                  )
              )
          }
          <p
            className="big-header"
            style={{
              marginBottom: '20px',
              textTransform: 'uppercase',
            }}
          >
            {roomId}
          </p>
          <ButtonGroup
            variant="contained"
            color="primary"
            aria-label="full-width contained primary button group"
          >
            <Button onClick={() => history.push('/')}>Poistu pelistä</Button>
          </ButtonGroup>
        </div>
      }
    </div>
  )
}

