import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App.jsx';
import * as serviceWorker from './serviceWorker';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const muiTheme = createMuiTheme({
  palette: {
    primary: { 500: '#6100ED' }
  },
  typography: {
    fontFamily: [
      'Rajdhani',
      'sans-serif',
    ].join(','),
    button: {
      fontSize: '18px',
    },
  },
  overrides: {
    PrivateNotchedOutline: {
      root: {
        borderColor: '#fff !important',
        color: '#fff'
      }
    },
    MuiFormLabel: {
      root: {
        color: '#fff',
        "&$focused": {
          color: "#fff"
        }
      },
    },
    MuiInputBase: {
      input: {
        color: '#fff',
      }
    }
  }
});

ReactDOM.render(
  <MuiThemeProvider theme={muiTheme}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </MuiThemeProvider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
