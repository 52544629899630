// constants shared between frontend and backend

// game state constants
const STATE_CREATED = 'created';
const STATE_WAITING = 'waiting';
const STATE_CHOOSING = 'choosing';
const STATE_SPECTATING = 'spectating';
const STATE_END = 'end';
const PLAYER_ME = 'me';
const PLAYER_OPPONENT = 'opponent';

export {
    STATE_CREATED,
    STATE_WAITING,
    STATE_CHOOSING,
    STATE_SPECTATING,
    STATE_END,
    PLAYER_ME,
    PLAYER_OPPONENT,
};
