import React from 'react'
import { useHistory } from 'react-router-dom';
import { Button, Box, TextField } from '@material-ui/core'

export default function CreateView({ createRoom, roomId, nicknameHandler, persistedSettings, errors }) {

  const history = useHistory();

  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        createRoom(persistedSettings.nickname);
      }}
      className="centered-container"
    >
      <p className="big-header">Värivaltaus</p>
      <p className="sub-header">Syötä nimimerkkisi ja luo uusi peli</p>
      <TextField
        label="Nimimerkki"
        value={persistedSettings.nickname}
        onChange={(e) => nicknameHandler(e.target.value)}
        margin="normal"
        variant="outlined"
        name="nickname"
      />
      <p style={{ color: 'red', fontWeight: 'bold', fontSize: 18, height: 10 }}>{errors}</p>
      <Box>
        <Button
          onClick={() => history.push('/')}
          variant="contained"
          color="primary"
          aria-label="full-width contained primary button group"
          style={{ marginRight: 20, backgroundColor: '#000', border: '1px solid #6100ed' }}
          type="button"
        >
          Takaisin
        </Button>
        <Button
          variant="contained"
          color="primary"
          aria-label="full-width contained primary button group"
          type="submit"
        >
          Luo peli
        </Button>
      </Box>
      <p style={{ color: '#fff', height: 16 }}>
        {roomId &&
          <span>
            <span>Kutsukoodi: </span>
            <span style={{ textTransform: 'uppercase' }}>
              {roomId}
            </span>
          </span>
        }
      </p>
    </form>
  )
}
