import React from 'react'
import { useHistory } from 'react-router-dom';
import { Button, Box } from '@material-ui/core'

export default function GuideModal({ dismissGuideModal }) {
  return (
    <div className="guide-modal">
      <div>
        <div className="guide-text">
          <p>
            Aloitat nurkasta, nimimerkkisi vierestä.
          </p>
          <p>
            Valitse yksi vapaana olevista väreistä vuorotellen vastustajasi kanssa.
          </p>
          <p>
            Jos oma alueesi koskettaa valitun värisiä ruutuja, ne liitetään alueeseesi.
          </p>
          <p>
            Enemmän ruutuja vallannut voittaa.
          </p>
        </div>
        <Box>
          <Button
            onClick={() => dismissGuideModal()}
            variant="contained"
            color="primary"
            aria-label="full-width contained primary button group"
            style={{ marginTop: 20 }}
          >
            Jatka peliin
          </Button>
        </Box>
      </div>
    </div>
  )
}
