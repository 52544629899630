import React from 'react'
import { useHistory } from 'react-router-dom';
import { Button, Box } from '@material-ui/core'

export default function OpponentGoneModal() {

  const history = useHistory();

  return (
    <div className="waiting-modal">
      <div>
        <p>Vastustajaan ei saada yhteyttä.</p>
        <p>Odota hetki tai poistu pelistä.</p>
        <Box>
          <Button
            onClick={() => history.push('/')}
            variant="contained"
            color="primary"
            aria-label="full-width contained primary button group"
            style={{ marginTop: 20 }}
          >
            Poistu pelistä
          </Button>
        </Box>
      </div>
    </div>
  )
}
